<template>
  <div class="minwidth w100 mw100">
    <tabbar></tabbar>

    <div class="positionrel w100">
      <img :src="require('../../public/img/join/pic1.png')" class="positionabs" />
      <div style="color: #fff;font-size: 3rem;position: absolute;top: 8rem;left: 50%;transform: translate(-50%);">
        行业资讯
      </div>
    </div>

    <div style="background-color: #f7f7f7;width: 100%;padding: 3rem 0 1rem 0;">
      <div v-for="(item,index) in msglist" style="display: flex;padding: 1rem;background-color: #fff;width: 50%;margin: 0 auto;justify-content: space-around;border-radius: 1rem;margin-bottom: 2rem;cursor: pointer;" @click="gotoNewsDetail(index)">
        <div style="width: 40%;text-align: center;">
          <img style="height: 12rem;width: 95%;object-fit:cover;" :src="item.url" alt="">
        </div>
        <div style="width: 50%;">
          <div style="font-size: 1.3rem;margin-top: 1rem;height: 3rem;">
            {{ item.title }}
          </div>
          <div style="font-size: 0.9rem;overflow: hidden;text-overflow: ellipsis;text-align: justify;margin-top: 2rem;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;line-clamp: 4;">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>

    <bottom></bottom>
  </div>
</template>

<script>
export default {
  data() {
    return {
      msglist: [
      {
          url: require('../../public/img/news/news0.png'),
          title: '蛇年春晚官宣！主题、主标识发布，又要过年啦',
          content: '蛟龙去，灵蛇来。11月29日，中央广播电视总台《2025年春节联欢晚会》发布主题和主标识。乙巳蛇年春晚将在欢乐吉祥、喜气洋洋的总基调中，以“巳巳如意，生生不息”为主题，与全球华人相约除夕、欢度农历新年。巳巳如意 生生不息、承古拓今 饱含深意。巳，象征着阳气巳出，阴气巳藏，万物见，成文章。而将两个“巳”字对称摆放，则恰似中国传统的如意纹样。双巳合璧，事事如意，这是乙巳蛇年与如意之间吉祥曼妙的创意链接，饱含喜庆美满的家国祝福，更彰显着中华民族精神根脉生生不息的时代力量。'
        },
        {
          url: require('../../public/img/news/news1.png'),
          title: '麦当劳中国与菜鸟共推“一箱一码”，餐饮供应链迎来数字化新突破',
          content: '近日，麦当劳中国与菜鸟携手，在中国国际供应链促进博览会上共同揭晓了一项智慧供应链的新突破——“一箱一码”项目。这一创新技术是在与麦当劳中国供应商伙伴的紧密合作下，由麦当劳中国发起，菜鸟提供物联网技术支持，多家供应商共同参与推进的。据了解，“一箱一码”技术已在麦当劳中国的上游工厂、物流中心及餐厅等多个环节成功部署，并在部分试点市场的麦当劳餐厅完成了全货品全链路的批量验证。这项技术通过RFID（射频识别）及其他物联网技术，在包装上为每件货品赋予了一个独一无二的“数字身份证”，极大地提升了供应链上下游的数字化协同能力和精细管理水平。'
        },
        {
          url: require('../../public/img/news/news2.png'),
          title: '华为与平安举行鸿蒙原生应用上架仪式：35款APP启动开发',
          content: '【CNMO科技消息】11月29日，华为HarmonyOS官方宣布，今日，华为与平安集团举行鸿蒙原生应用正式上架仪式，平安集团已有35款应用全面启动鸿蒙原生应用开发，其中23款应用已成功上架鸿蒙原生版，这一举措标志着双方在金融服务体验和企业办公效率方面的深入合作。据悉，平安集团依托华为HarmonyOS NEXT的创新能力，旨在为客户打造更加便捷易用的综合金融服务体验。这一合作不仅有助于提升平安集团的服务质量和客户体验，同时也进一步丰富了鸿蒙生态的应用场景和生态价值。'
        },
        {
          url: require('../../public/img/news/news3.png'),
          title: '霍金为什么认为外星科技要比地球强？主要原因是它暴露了科技水平',
          content: '宇宙广阔无垠，自从人类开始探索宇宙之谜以来，一直在寻找地外文明。然而，尽管经过多年的努力，我们依然没有发现任何明确的迹象。人类对外星文明的探索，不仅源于对智慧生命的好奇心，也希望通过与外星文明的交流，获取彼此的技术优势，从而加速自我的科技进步。然而，并不是每个人都乐见与外星文明的接触。以著名物理学家霍金为例，他曾多次警示人类不要主动与外星生命联系。在他看来，外星文明未必是友好的，由于他们的科技可能远远领先于我们，人类的鲁莽尝试可能带来不可预知的灾难。霍金之所以坚信外星科技优于地球，是因为他们展现出的科技水平已然显示出这一点。'
        }
      ],

      num: 0,
    };
  },

  mounted() {
    this.num = this.$route.query.num;
  },

  methods: {
    gotoNewsDetail(num) {
      this.$router.push({ path: '/newsDetail', query: { num: num } })
    }
  },

  
};
</script>

<style scoped>
.w100 {
  width: 100%;
}

.w60 {
  width: 60%;
}

.minwidth {
  min-width: 1000px;
}

.flex {
  display: flex;
}

.textcent {
  text-align: center;
}

.textalign_justify {
  text-align: justify;
  line-height: 25px;
}

.fontsize50 {
  font-size: 50px;
}

.fontsize30 {
  font-size: 30px;
}

.fontsize20 {
  font-size: 20px;
}

.fontsize16 {
  font-size: 16px;
}

.fontsize14 {
  font-size: 14px;
}

.fontsize12 {
  font-size: 12px;
}

.fontcolorb0 {
  color: #b0b0b0;
}

.margincent {
  margin: 0 auto;
}

.margincentY {
  margin: auto 0;
}

.margintop50 {
  margin-top: 50px;
}

.margintop70 {
  margin-top: 70px;
}

.marginleft20 {
  margin-left: 20px;
}

.marginbot70 {
  margin-bottom: 70px;
}

#job:hover {
  color: #bc9158;
}

.positionrel {
  position: relative;
  height: 340px;
  overflow: hidden;
}

.positionabs {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  width: 1920px;
  -webkit-filter: contrast(50%);
  filter: contrast(50%);
}

@media screen and (max-width: 750px) {
  .minwidth {
    min-width: 0px;
  }

  .mw100 {
    width: 100vw;
  }

  .flexnone {
    display: block;
  }

  .w80 {
    width: 80%;
  }

  .positionrel {
    position: relative;

    height: 179px;
    overflow: hidden;
  }

  .positionabs {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

    width: 780px;
  }
}
</style>